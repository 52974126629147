import React, { ReactElement } from "react";

export default function EsophagealCancer(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="45"
            height="45"
            fill="none"
            viewBox="0 0 100 100"
        >
            <mask
                id="mask0_2_3043"
                style={{ maskType: "alpha" }}
                width="100"
                height="100"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H100V100H0z"></path>
            </mask>
            <g mask="url(#mask0_2_3043)">
                <path
                    fill="#B3B3B3"
                    d="M91.585 50.298l-7.533-10.546C81.972 18.869 70.763 7.254 51.735 6.08a38.393 38.393 0 00-28.34 9.943 35.334 35.334 0 00-11.39 25.582 35.968 35.968 0 0016.572 30.93c5.696 3.677 10.547 12.52 10.547 19.316a1.507 1.507 0 001.507 1.507h21.092a1.507 1.507 0 001.507-1.507v-9.19a4.523 4.523 0 014.52-4.52c6.598 0 10.817-.105 13.348-2.636 3.013-3.013 3.013-8.407 3.013-20.294l2.17-.843 4.52-1.793c.433-.165.767-.52.903-.964a1.508 1.508 0 00-.12-1.31v-.003zM68.669 75.126h-.919a7.536 7.536 0 00-7.533 7.533v7.684h-7.533V66.026a17.882 17.882 0 0117.868-17.868h.573a18.715 18.715 0 0111.842 4.263l-.904.362c-.574.228-.95.784-.95 1.401v7.533H76.79a1.507 1.507 0 000 3.013h4.218c-.18 4.52-.663 7.307-2.004 8.648-1.34 1.34-4.971 1.748-10.335 1.748zm17.447-23.954a21.803 21.803 0 00-14.99-6.026h-.574a20.915 20.915 0 00-20.881 20.881v24.317H42.14c-.618-7.533-5.695-16.376-11.842-20.338A32.977 32.977 0 0115.02 41.652 32.352 32.352 0 0125.446 18.24a35.214 35.214 0 0126.109-9.145C69.257 10.18 79.245 20.71 81.16 40.417c.021.26.11.508.256.723l6.599 9.326-1.899.706z"
                ></path>
                <path
                    fill="#C1312C"
                    d="M50.514 82.81a.753.753 0 010-1.508c.464-.004.927-.04 1.386-.105a.74.74 0 01.859.633.769.769 0 01-.633.859 11.44 11.44 0 01-1.597.12h-.015zm-4.384-.905c-.1.021-.202.021-.301 0a9.817 9.817 0 01-1.417-.753.74.74 0 01-.24-1.04.769.769 0 01.979-.316c.395.25.808.472 1.235.663a.77.77 0 11-.317 1.507l.061-.06zm10.064-.814a.753.753 0 01-.407-1.386 9.713 9.713 0 001.1-.843.769.769 0 011.07 0 .738.738 0 010 1.054 8.993 8.993 0 01-1.28.98.795.795 0 01-.483.195zM41.61 78.078a.767.767 0 01-.633-.346 11.545 11.545 0 01-.783-1.401.738.738 0 01.437-1.055.753.753 0 011.01.347c.193.424.42.832.678 1.22a.753.753 0 01-.212 1.04.737.737 0 01-.497.18v.015zm18.44-1.507a.69.69 0 01-.3 0 .751.751 0 01-.392-.994c.196-.423.357-.861.482-1.31a.782.782 0 011.506.421 12.095 12.095 0 01-.542 1.507.77.77 0 01-.753.392v-.016zm-20.172-4.308a.707.707 0 01-.753-.678v-.075c.002-.54.043-1.078.12-1.613a.754.754 0 01.844-.632.769.769 0 01.648.859 8.47 8.47 0 00-.106 1.386.813.813 0 01-.753.813v-.06zm20.972-1.507a.739.739 0 01-.739-.632 9.84 9.84 0 00-.301-1.356.769.769 0 01.497-.95.77.77 0 01.95.513c.15.494.265.997.346 1.507a.75.75 0 01-.633.858l-.12.06zm-19.3-4.188a.645.645 0 01-.406-.12.739.739 0 01-.226-1.04c.286-.453.608-.88.964-1.28a.738.738 0 011.054-.09c.29.297.29.77 0 1.069a8.62 8.62 0 00-.798 1.13.77.77 0 01-.588.331zm16.813-1.25a.782.782 0 01-.572-.256c-.303-.35-.63-.676-.979-.98a.753.753 0 11.98-1.145c.407.35.79.728 1.144 1.13.29.299.29.772 0 1.07a.784.784 0 01-.572.18zm-12.339-2.636a.77.77 0 01-.316-1.507c.488-.224.991-.41 1.507-.558a.782.782 0 01.422 1.507 8.304 8.304 0 00-1.296.482.833.833 0 01-.316.075h-.001zm7.337-.558h-.211a8.511 8.511 0 00-1.37-.301.754.754 0 01-.603-.86.753.753 0 01.844-.647c.533.08 1.062.19 1.582.332.394.123.62.535.512.933a.738.738 0 01-.753.543z"
                ></path>
                <circle cx="50.5" cy="71.5" r="8.5" fill="#C1312C"></circle>
            </g>
        </svg>
    );
}
