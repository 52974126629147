import React, { ReactElement } from "react";

export default function Leukemia(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="45"
            height="45"
            fill="none"
            viewBox="0 0 100 100"
        >
            <mask
                id="mask0_78_7"
                style={{ maskType: "alpha" }}
                width="100"
                height="100"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H100V100H0z"></path>
            </mask>
            <g fillRule="evenodd" clipRule="evenodd" mask="url(#mask0_78_7)">
                <path
                    fill="#B3B3B3"
                    d="M50.46 7.332c23.693 0 42.977 19.282 42.977 42.978S74.155 93.29 50.46 93.29 7.481 74.006 7.481 50.31C7.501 26.594 26.763 7.332 50.46 7.332zm0 87.903c12.012 0 23.28-4.68 31.772-13.152 8.492-8.492 13.153-19.78 13.153-31.773 0-12.013-4.681-23.281-13.153-31.773-8.492-8.492-19.78-13.152-31.773-13.152-12.013 0-23.281 4.68-31.773 13.152C10.194 27.03 5.513 38.317 5.513 50.31c0 11.993 4.681 23.281 13.173 31.773 8.493 8.472 19.78 13.153 31.773 13.153z"
                ></path>
                <path
                    fill="#B3B3B3"
                    d="M50.46 15.141c19.387 0 35.168 15.782 35.168 35.169 0 19.386-15.762 35.148-35.149 35.148-19.386 0-35.148-15.762-35.148-35.148-.02-19.388 15.762-35.169 35.128-35.169zm0 72.286c20.463 0 37.116-16.652 37.116-37.117 0-20.464-16.652-37.117-37.117-37.117-20.464 0-37.117 16.652-37.117 37.117 0 20.464 16.653 37.117 37.117 37.117z"
                ></path>
                <path
                    fill="#C1312C"
                    d="M35.256 44.947c6.421 0 11.662 3.583 11.662 7.974s-5.22 7.974-11.662 7.974c-6.42 0-11.661-3.583-11.661-7.974.021-4.412 5.24-7.974 11.661-7.974zm0 17.895c7.498 0 13.608-4.453 13.608-9.942 0-5.468-6.11-9.942-13.608-9.942S21.65 47.41 21.65 52.9s6.11 9.942 13.607 9.942z"
                ></path>
                <path
                    fill="#C1312C"
                    d="M41.035 54.908a.983.983 0 00.622-1.74c-3.397-2.878-7.643-3.044-12.634-.496a.99.99 0 00-.436 1.325.99.99 0 001.326.436c4.288-2.175 7.705-2.092 10.48.248.208.165.435.227.642.227zM33.06 27.775a4.8 4.8 0 014.785 4.784 4.8 4.8 0 01-4.785 4.785 4.8 4.8 0 01-4.784-4.785 4.774 4.774 0 014.784-4.784zm0 11.516a6.754 6.754 0 006.753-6.753 6.754 6.754 0 00-6.753-6.752 6.754 6.754 0 00-6.752 6.752 6.754 6.754 0 006.752 6.753zM77.324 47.224c-.104 5.282-4.163 8.617-7.933 9.093-1.056.125-4.557.29-5.51-3.148-.456-1.636-.373-2.465-.269-3.439.063-.641.124-1.346.041-2.299-.372-4.35-2.878-6.317-4.909-7.187-2.071-.89-4.039-.85-4.245-.828-2.424.04-4.143-.518-5.158-1.72-1.242-1.47-1.035-3.438-.89-4.204 1.118-5.883 6.524-7.456 11.185-6.98 8.74.89 17.916 8.471 17.688 20.712zm-8.865 11.123c.373 0 .766-.02 1.16-.083 2.381-.29 4.742-1.492 6.503-3.294 2.01-2.05 3.107-4.722 3.17-7.704.144-7.933-3.273-13.153-6.193-16.135-3.584-3.687-8.41-6.07-13.256-6.587-6.959-.725-12.2 2.63-13.318 8.554-.414 2.217.063 4.35 1.326 5.841 1.408 1.678 3.687 2.485 6.71 2.424h.02c.27-.021 6.566-.208 7.126 6.234.063.767.02 1.347-.041 1.947-.104 1.078-.207 2.175.331 4.143.786 2.92 3.272 4.66 6.462 4.66z"
                ></path>
                <path
                    fill="#C1312C"
                    d="M69.412 50.082a.979.979 0 00.89-.56c1.885-4.018.954-8.18-2.796-12.344a.982.982 0 00-1.387-.063 1.001 1.001 0 00-.083 1.388c3.21 3.563 4.018 6.897 2.485 10.19A.977.977 0 0068.998 50c.145.062.29.083.414.083zM66.222 65.617c.911 3.376-.228 6.753-2.465 7.353-2.257.601-4.909-1.74-5.82-5.136-.91-3.376.228-6.753 2.465-7.354 2.237-.6 4.909 1.76 5.82 5.137zm-3.19 9.404a4.86 4.86 0 001.222-.166c3.335-.89 5.054-5.26 3.853-9.755-1.202-4.494-4.889-7.415-8.223-6.525-3.335.89-5.054 5.261-3.853 9.756 1.057 3.956 4.019 6.69 7.001 6.69z"
                ></path>
                <path
                    fill="#C1312C"
                    d="M62.37 71.147a.98.98 0 00.89-.56c1.181-2.526.622-5.136-1.719-7.704a1.002 1.002 0 00-1.387-.083 1.001 1.001 0 00-.083 1.388c1.78 1.967 2.236 3.79 1.387 5.57a.977.977 0 00.477 1.306c.145.063.29.083.435.083zM45.447 66.175c.953 0 1.803.29 2.403.891.766.766 1.056 1.947.828 3.314-.248 1.491-1.097 3.024-2.36 4.288-1.264 1.263-2.776 2.092-4.288 2.36-1.367.229-2.548-.062-3.314-.828s-1.057-1.947-.829-3.314c.249-1.491 1.098-3.024 2.361-4.288 1.574-1.573 3.542-2.422 5.2-2.422zM41.12 79.06c.393 0 .808-.041 1.242-.104 1.906-.331 3.791-1.346 5.344-2.9 3.294-3.272 3.977-7.932 1.532-10.376-2.444-2.444-7.104-1.76-10.376 1.533-1.554 1.553-2.569 3.438-2.9 5.343-.352 2.03.145 3.811 1.367 5.033.974.974 2.3 1.471 3.79 1.471z"
                ></path>
                <path
                    fill="#C1312C"
                    d="M41.139 75.288c.414 0 .808-.269.932-.683.83-2.527 2.175-3.832 4.122-3.998a.976.976 0 00.89-1.056c-.04-.538-.517-.953-1.056-.89-2.797.227-4.743 2.029-5.82 5.343a.998.998 0 00.62 1.243.907.907 0 00.312.042zM55.577 34.9c.227 0 .476-.082.662-.248 1.781-1.595 2.983-1.325 3.728-.973a.977.977 0 001.305-.477.977.977 0 00-.476-1.305c-1.326-.621-3.438-.911-5.883 1.284a1.002 1.002 0 00-.083 1.388.995.995 0 00.747.332zM30.97 34.01c.187 0 .373-.063.538-.165 1.16-.787 2.258-.995 3.293-.622a.954.954 0 001.243-.6.954.954 0 00-.6-1.243c-1.657-.58-3.356-.29-5.034.85a.994.994 0 00-.27 1.366c.208.27.52.414.83.414z"
                ></path>
            </g>
        </svg>
    );
}
