import React, { ReactElement } from "react";

import Leukemia from "../icons/Leukemia";
import Lymphoma from "../icons/Lymphoma";
import Myeloma from "../icons/Myeloma";
import Parkinson from "../icons/Parkinson";
import BladderCancer from "../icons/BladderCancer";
import KidneyCancer from "../icons/KidneyCancer";
import EsophagealCancer from "../icons/EsophagealCancer";
import LungCancer from "../icons/LungCancer";
import FeatureList from "@components/shared/featureList";
import Image from "next/image";
const illnesses = [
    {
        title: "Leukemia",
        icon: <Leukemia />,
    },
    {
        title: "Multiple Myeloma",
        icon: <Myeloma />,
    },
    {
        title: "Non-Hodgkin's Lymphoma",
        icon: <Lymphoma />,
    },
    {
        title: "Parkinsons Disease",
        icon: <Parkinson />,
    },
    {
        title: "Esophageal Cancer",
        icon: <EsophagealCancer />,
    },
    {
        title: "Lung Cancer",
        icon: <LungCancer />,
    },
    {
        title: "Bladder Cancer",
        icon: <BladderCancer />,
    },
    {
        title: "Kidney Cancer",
        icon: <KidneyCancer />,
    },
];

export default function WhyUs(): ReactElement {
    return (
        <div className=" flex gap-16 flex-col lg:flex-row lg:mb-28 mb-14 lg:mt-24 mt-10 ">
            <FeatureList
                featureList={illnesses}
                mainTitle="Eligibility"
                mainDescription="If you have been diagnosed with any of the severe illnesses listed below and lived at Camp Lejeune for at least 30 days between 1953 and 1987, you may qualify for compensation:"
                classes={{
                    mainSectionClasses:
                        "flex flex-col    md:max-w-[620px] lg:max-w-[1000px] mx-auto lg:mx-0",
                    mainTitleClasses: "font-bold text-xl lg:text-3xl ",
                    mainDescriptionClasses:
                        "text-sm lg:text-base mt-[5px] leading-8 font-light",
                    innerSectionClasses:
                        "flex flex-wrap justify-between  mt-[56px]",
                    oneBoxClasses:
                        " mb-[24px]   w-[300px] flex items-center gap-[20px]",
                    titleClasses: "text-sm lg:text-base  ",
                    iconClasses: " ",
                    descriptionClasses: " hidden",
                }}
                colors={{
                    mainTitleColor: "#1B2F48",
                }}
            />
            <div className="relative sm:w-[528px] sm:h-[444px] sm:min-w-[528px] w-[300px] h-[260px] lg:mt-20 mx-auto lg:mx-0">
                <Image
                    src={"/camplejeuneapplication/assets/images/whyUsImage.jpg"}
                    layout="fill"
                    alt="Image"
                />
            </div>
        </div>
    );
}
